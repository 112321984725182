// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-administorial-section-js": () => import("./../../../src/pages/administorial_section.js" /* webpackChunkName: "component---src-pages-administorial-section-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-career-planning-how-it-works-js": () => import("./../../../src/pages/career_planning/how_it_works.js" /* webpackChunkName: "component---src-pages-career-planning-how-it-works-js" */),
  "component---src-pages-career-planning-index-js": () => import("./../../../src/pages/career_planning/index.js" /* webpackChunkName: "component---src-pages-career-planning-index-js" */),
  "component---src-pages-career-planning-schedule-js": () => import("./../../../src/pages/career_planning/schedule.js" /* webpackChunkName: "component---src-pages-career-planning-schedule-js" */),
  "component---src-pages-casper-how-it-works-js": () => import("./../../../src/pages/casper/how_it_works.js" /* webpackChunkName: "component---src-pages-casper-how-it-works-js" */),
  "component---src-pages-casper-index-js": () => import("./../../../src/pages/casper/index.js" /* webpackChunkName: "component---src-pages-casper-index-js" */),
  "component---src-pages-casper-pricing-js": () => import("./../../../src/pages/casper/pricing.js" /* webpackChunkName: "component---src-pages-casper-pricing-js" */),
  "component---src-pages-casper-schedule-js": () => import("./../../../src/pages/casper/schedule.js" /* webpackChunkName: "component---src-pages-casper-schedule-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editing-applications-how-it-works-js": () => import("./../../../src/pages/editing_applications/how_it_works.js" /* webpackChunkName: "component---src-pages-editing-applications-how-it-works-js" */),
  "component---src-pages-editing-applications-index-js": () => import("./../../../src/pages/editing_applications/index.js" /* webpackChunkName: "component---src-pages-editing-applications-index-js" */),
  "component---src-pages-editing-applications-schedule-js": () => import("./../../../src/pages/editing_applications/schedule.js" /* webpackChunkName: "component---src-pages-editing-applications-schedule-js" */),
  "component---src-pages-gmat-how-it-works-js": () => import("./../../../src/pages/gmat/how_it_works.js" /* webpackChunkName: "component---src-pages-gmat-how-it-works-js" */),
  "component---src-pages-gmat-index-js": () => import("./../../../src/pages/gmat/index.js" /* webpackChunkName: "component---src-pages-gmat-index-js" */),
  "component---src-pages-gmat-schedule-js": () => import("./../../../src/pages/gmat/schedule.js" /* webpackChunkName: "component---src-pages-gmat-schedule-js" */),
  "component---src-pages-gre-how-it-works-js": () => import("./../../../src/pages/gre/how_it_works.js" /* webpackChunkName: "component---src-pages-gre-how-it-works-js" */),
  "component---src-pages-gre-index-js": () => import("./../../../src/pages/gre/index.js" /* webpackChunkName: "component---src-pages-gre-index-js" */),
  "component---src-pages-gre-schedule-js": () => import("./../../../src/pages/gre/schedule.js" /* webpackChunkName: "component---src-pages-gre-schedule-js" */),
  "component---src-pages-guided-tour-js": () => import("./../../../src/pages/guidedTour.js" /* webpackChunkName: "component---src-pages-guided-tour-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructors-humayun-ahmed-js": () => import("./../../../src/pages/instructors/humayun_ahmed.js" /* webpackChunkName: "component---src-pages-instructors-humayun-ahmed-js" */),
  "component---src-pages-instructors-index-js": () => import("./../../../src/pages/instructors/index.js" /* webpackChunkName: "component---src-pages-instructors-index-js" */),
  "component---src-pages-instructors-sohaib-ahmed-js": () => import("./../../../src/pages/instructors/sohaib_ahmed.js" /* webpackChunkName: "component---src-pages-instructors-sohaib-ahmed-js" */),
  "component---src-pages-instructors-sophia-glisch-js": () => import("./../../../src/pages/instructors/sophia_glisch.js" /* webpackChunkName: "component---src-pages-instructors-sophia-glisch-js" */),
  "component---src-pages-interview-preparation-how-it-works-js": () => import("./../../../src/pages/interview_preparation/how_it_works.js" /* webpackChunkName: "component---src-pages-interview-preparation-how-it-works-js" */),
  "component---src-pages-interview-preparation-index-js": () => import("./../../../src/pages/interview_preparation/index.js" /* webpackChunkName: "component---src-pages-interview-preparation-index-js" */),
  "component---src-pages-interview-preparation-schedule-js": () => import("./../../../src/pages/interview_preparation/schedule.js" /* webpackChunkName: "component---src-pages-interview-preparation-schedule-js" */),
  "component---src-pages-lsat-how-it-works-js": () => import("./../../../src/pages/lsat/how_it_works.js" /* webpackChunkName: "component---src-pages-lsat-how-it-works-js" */),
  "component---src-pages-lsat-index-js": () => import("./../../../src/pages/lsat/index.js" /* webpackChunkName: "component---src-pages-lsat-index-js" */),
  "component---src-pages-lsat-pricing-js": () => import("./../../../src/pages/lsat/pricing.js" /* webpackChunkName: "component---src-pages-lsat-pricing-js" */),
  "component---src-pages-lsat-schedule-js": () => import("./../../../src/pages/lsat/schedule.js" /* webpackChunkName: "component---src-pages-lsat-schedule-js" */),
  "component---src-pages-mcat-how-it-works-js": () => import("./../../../src/pages/mcat/how_it_works.js" /* webpackChunkName: "component---src-pages-mcat-how-it-works-js" */),
  "component---src-pages-mcat-index-js": () => import("./../../../src/pages/mcat/index.js" /* webpackChunkName: "component---src-pages-mcat-index-js" */),
  "component---src-pages-mcat-pricing-js": () => import("./../../../src/pages/mcat/pricing.js" /* webpackChunkName: "component---src-pages-mcat-pricing-js" */),
  "component---src-pages-mcat-schedule-js": () => import("./../../../src/pages/mcat/schedule.js" /* webpackChunkName: "component---src-pages-mcat-schedule-js" */),
  "component---src-pages-payment-cancelled-js": () => import("./../../../src/pages/payment_cancelled.js" /* webpackChunkName: "component---src-pages-payment-cancelled-js" */),
  "component---src-pages-payment-successful-js": () => import("./../../../src/pages/payment_successful.js" /* webpackChunkName: "component---src-pages-payment-successful-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

